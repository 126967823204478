
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

































































































































































































































































































































































.my-meter-info,
[class*='my-meter-info--'] {
  padding-top: 0;
}

.my-meter-info__toggle {
  margin: 0px 10px;

  .is-pending {
    pointer-events: none;
    opacity: 0.8;
  }
}

.my-meter-info__toggle__wrapper {
  display: flex;
  align-items: center;
}

.my-meter-info__table {
  width: 100%;
  border-top: 3px solid $c-black;
  border-collapse: collapse;
}

.my-meter-info__table__label,
.my-meter-info__table__value {
  padding: 1rem 0;
  font-size: 1.4rem;
  border-bottom: 1px solid $c-gray-light;
}

.my-meter-info__table__label {
  @extend %fw-medium;
  width: 70%;
}

.my-meter-info__table__value {
  @extend %fw-normal;

  width: 10rem;

  @include mq('xs') {
    width: 16rem;
  }

  @include mq('s') {
    width: 20rem;
  }
}

.tip__icon {
  @include mq(m) {
    margin-bottom: -2px;
  }
  fill: $c-main-primary;
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
  margin-left: 1em;
}
